.checkable__bg {
  @include position(absolute, 0 0 0 0);

  pointer-events: none;

  background: v(accent);
  @include border(v(accent));
  border-radius: v(checkable-radius);

  transition: transform .3s $ease-out-back;
}

.filters__controls {
  display: flex;

  @media (--to-filters-large) {
    padding: 1rem v(x-space);
    @include position(fixed, null 0 0 0);
    background: v(bg);
  }

  @media (--filters-large) {
    grid-area: -2 / -2;
  }
}

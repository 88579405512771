// @import url('https://fast.fonts.net/lt/1.css?apiType=css&c=508ae313-0de1-42f2-a1d3-35ce6ba188a4&fontids=5664093,5664103,5664149');

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/../../fonts/helvetica-neue/helvetica-neue-regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/../../fonts/helvetica-neue/helvetica-neue-medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/../../fonts/helvetica-neue/helvetica-neue-bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

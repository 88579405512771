.person__details {
  margin-top: -1.6em;
  padding: 0 var(--person-x-space) var(--person-x-space);
}

.person__details__title {
  margin: 1em 0 0;

  font-size: 1.8rem;
}

.person__details__toggle {
  display: inline-block;

  cursor: pointer;
  list-style-type: none;

  &::-webkit-details-marker {
    display: none; // Chrome
  }

  &:focus,
  &:hover {
    color: v(accent);

    @include outline(v(accent), .1rem, dashed, .3rem);
  }
}

.person__details__content {}

.person__skills {
  padding-left: 1.4em;

  list-style-type: disc;
  opacity: .7;
}

.person__blockquote {
  quotes: "“" "”";

  position: relative;

  opacity: .7;

  &::before { content: open-quote; }

  &::after { content: close-quote; }
}

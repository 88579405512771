$auth-w: (
  'auth-1-col': 10em,
  'auth-2-col': 50em,
);

@include --w($auth-w);

.auth__form {
  --auth-submit-h: 6rem;
  margin: 0 auto;
  padding: 0 v(x-space);

  max-width: 40rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  font-weight: 500;
}

.auth__button {
  margin-top: v(auth-submit-h);
  height: v(auth-submit-h);

  font-size: 1.6rem;
}

.auth__button__label {
  justify-content: center;
}

.auth__forgotten-link {
  margin-top: 1rem;
  padding-left: 1rem;
}

.filter__legend {
  --filter-radius: .2666em;

  position: relative;
  width: 100%;
  height: 3em;

  font-size: 1.5rem;

  .filter--visible & {
    @media (--to-filters-large) {
      transition: max-width .5s $ease-out-quad;
      will-change: max-width;
    }
  }

  .filter--visible.filter--has-selected-tags & {
    @media (--to-filters-large) {
      max-width: calc(100% - 1.4rem);

      transition: max-width .3s $ease-out-back;
    }
  }
}

.filter__btn {
  padding: 0 2rem;

  width: 100%;
  height: 100%;

  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &::before {
    content: '';
    @include position(absolute, 0 0 0 0);

    @include border(v(primary), .2rem);
    border-radius: v(filter-radius);
  }
}

.filter__label__txt {
  position: relative;
  z-index: 3;

  white-space: nowrap;
}

.filter__count {
  width: 1.533em;
  height: 1.533em;

  z-index: 1;

  display: inline-flex;

  background: v(primary);
  color: v(accent);
  border-radius: 50%;

  transition: transform .3s $ease-out-back;

  &:not(.filter__count--visible) {
    transform: translate3d(0, 0, 0) scale(0);
    transition-timing-function: $ease-in-quad;
  }
}

.filter__dropdown-triangle {
  @include position(absolute, null 2rem null null);

  transition: opacity .3s $ease-out-quad 1s, transform .3s $ease-out-quad 1s;

  &:not(.filter__dropdown-triangle--visible) {
    opacity: 0;
  }
}

.filter__btn__bg {
  @include position(absolute, 0 0 0 0);

  pointer-events: none;

  @include border(transparent, .2rem);
  border-radius: v(filter-radius);

  // Filter background
  &::before {
    content: '';
    @include position(absolute, -.2rem -.2rem -.2rem -.2rem);

    pointer-events: none;

    background: v(accent);
    @include border(v(accent), .2rem);
    border-radius: calc(var(--filter-radius) - .1rem);

    .filter:not(.filter--visible):not(.filter--has-selected-tags) & {
      opacity: 0;
    }
  }

  // Unselected filter overlay
  &::after {
    content: '';
    @include position(absolute, -.2rem -.2rem -.2rem -.2rem);
    z-index: 3;

    pointer-events: none;

    background: v(bg);
    @include border(v(bg), .2rem);
    border-radius: calc(var(--filter-radius) - .1rem);
    opacity: 0;

    .filter-visible .filter:not(.filter--visible) & {
      opacity: .5;
    }

    .filter:not(.filter--visible) & {
      transition: opacity .3s $ease-out-quad;
    }
  }
}

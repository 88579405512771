.checkable__input {

  &:not(:checked) {

    + .checkable__bg {
      transform: translate3d(0, 0, 0) scale(.75);

      opacity: 0;
    }
    ~ .checkable__txt {
      .checkable__tag {
        background: transparent;
      }
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main {
  margin-top: 8rem;

  .filter-visible & {
    @include position(fixed, 0 0 0 0);
    margin-top: v(x-space);

    display: flex;
    flex-direction: column;

    @media (--filters-large) {
      margin-top: 15rem;
    }
  }
}

.main__title {
  margin-bottom: 3rem;
  padding: 0 v(x-space);

  font-size: 4rem;
  line-height: 1;

  @media (--filters-small) {
    font-size: 6rem;
  }

  @media (--filters-large) {
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0;

    width: 105rem;

    line-height: 1.2;
  }

  .filter-visible & {
    @media (--to-filters-large) {
      display: none;
    }
  }
}

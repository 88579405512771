.checkable__txt {
  position: relative;
  z-index: 3;

  white-space: nowrap;
}

.checkable__tag {
  margin-left: .25em;
}

.error {
  padding-left: 1rem;

  border-left: .2rem solid;
  color: v(accent);

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.checkable {
  --checkable-radius: 1.25em;

  height: 2.5em;
  margin: .5rem;
  padding: 0 2rem;

  position: relative;
  display: inline-flex;

  opacity: 0;

  .filter--visible & {
    animation: .2s $ease-out-quad forwards .4s;
    animation-name: opacity-0-to-1, from-left;
    @include animation-split(6, .3);

    @include after-first(6) {
      animation-duration: .4s;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &::before {
    content: '';
    @include position(absolute, 0 0 0 0);

    @include border(v(primary));
    border-radius: v(checkable-radius);
    opacity: .5;
  }
}

.checkable--reset,
.checkable--reset-all {
  --accent: #{$cardinal};
}

.checkable--validate {
  --accent: #{$governor-bay};
}

.checkable--reset {
  transition: opacity .6s $ease-in-out-quad;

  .filter-visible .filter--visible & {
    animation-delay: .2s;
    animation-duration: .25s;
  }

  .filter:not(.filter--visible) & {
    display: none;
  }

  .filter:not(.filter--has-selected-tags) & {
    pointer-events: none;
    opacity: 0;

    animation: none;
  }

  @media (--to-filters-large) {
    justify-self: flex-start;
  }
}

.checkable--reset-all {
  .filter-visible & {
    @media (--to-filters-large) {
      display: none;
    }
  }
}

.checkable--reset-all,
.checkable--validate {
  opacity: 1;

  flex: 1 0 auto;
  height: 4rem;

  font-size: 1.6rem;
  font-weight: 700;
}

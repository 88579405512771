.auth-page {

  &::before {
    content: '';
    @include position(absolute, 0 0 0 0);
    z-index: -1;

    background: url('https://source.unsplash.com/lFv0V3_2H6s/1600x900') center top / cover no-repeat;
    opacity: .3;
  }
}

.auth-page__grid {
  display: grid;
  grid-template-rows: auto 1fr;
}

.auth-page__header {
  position: relative;
  padding: v(x-space);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-page__main {
  display: grid;
  align-items: center;
}

.filters__results {
  order: -1;
  z-index: 1;
  padding: 0 var(--x-space);

  text-align: center;

  @media (--filters-large) {
    margin-left: auto;
    margin-right: auto;
    padding: 0;

    width: 100%;
    max-width: 105rem;
  }
}

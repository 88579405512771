.filter {
  .filter-visible &:not(.filter--visible) {
    @media (--to-filters-large) {
      display: none;
    }
  }
}

.filter__inner {
  display: grid;
  grid-template-columns: auto;

  @media (--filters-open-is-not-full-width-to-filters-large) {
    grid-template-columns: minmax(15.5rem, 24.2rem) auto;
  }

  .filter--visible.filter--has-selected-tags & {
    @media (--to-filters-large) {
      grid-template-columns: minmax(15.5rem, 24.2rem) auto;
    }
  }
}

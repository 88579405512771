.filters-form {
  flex: 1 0 auto;

  margin-bottom: 2.7rem;
  padding: 0 v(x-space);

  position: relative;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15.5rem, 1fr));
  grid-gap: 2rem;

  @media (--filters-small) {
    margin-bottom: 10.7rem;
    margin-left: -.5rem;
    margin-right: -.5rem;

    grid-gap: 2rem 1.5rem;
  }

  @media (--filters-large) {
    margin-left: auto;
    margin-right: auto;
    padding: 0;

    width: 105rem;

    grid-template-rows: repeat(auto-fill, 4.5rem);
  }

  .filter-visible & {

    @media (--to-filters-large) {
      grid-template-columns: auto;
    }

    &::before {
      @media (--filters-large) {
        pointer-events: auto;

        opacity: .7;
      }
    }
  }

  &::before {
    @media (--filters-large) {
      content: '';
      @include position(fixed, 0 0 0 0);

      pointer-events: none;

      background: v(bg);
      opacity: 0;

      transition: opacity .6s $ease-in-out-quad;
    }
  }
}

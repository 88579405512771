.copy {
  position: relative;
}

.copy__message {
  @include position(absolute, 50% 100% null null);
  transform: translate3d(-1em, -50%, 0);

  padding: .25em;

  pointer-events: none;

  background: v(person-bg);
  border-bottom: .1rem dashed;
  box-shadow: 0 0 3rem 1rem v(bg);
  opacity: 0;

  animation:
    from-right-starting-y-minus-50 .4s $ease-out-circ forwards,
    opacity-0-to-1 .5s $ease-in-out-quad forwards;
}

.copy__btn {
  &:hover,
  &:focus {
    .copy__icon {
      stroke: v(primary);
    }

    .copy__rect {
      transform: translate3d(-2%, -2%, 0);
    }
  }

  &:active {
    .copy__rect {
      transform: translate3d(-10%, -10%, 0);
      transition-duration: .05s;
    }
  }
}

.copy__icon {
  stroke: v(accent);

  overflow: visible;

  transition: stroke .3s $ease-in-out-quad;
}

.copy__rect {
  transition: transform .3s $ease-in-out-quad;
}

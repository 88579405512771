.person {
  --person-bg: #{$black-two};
  --person-x-space: 2rem;

  position: relative;

  overflow: hidden;

  background: v(person-bg);
  border-radius: .4rem;
  opacity: 0;

  animation: .2s $ease-out-quad forwards .4s;
  animation-name: opacity-0-to-1, from-left;
  @include animation-split(6, .3);

  @include after-first(6) {
    animation-duration: .4s;
  }


  &:not(:hover) {
    .person__pic-ctn {
      filter: grayscale(100%);
    }
  }
}

.person__inner {
}

.person__card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.person__name {
  padding: .2em v(person-x-space) 0;

  font-size: 2.2rem;
  line-height: 1.4;
}

.person__job-title {
  padding: 0 v(person-x-space);

  font-size: 1.5rem;
}

.person__pic-ctn {
  margin-bottom: 1rem;
  order: -1;

  transition: filter .15s $ease-in-out-quad;
}

.person__pic {}

.person__address {
  margin-top: 1rem;
  padding: 0 v(person-x-space) v(person-x-space);
}

.person__address-row {
  margin-bottom: .5em;

  display: grid;
  grid-template-columns: 3rem 1fr 2.4rem;
  align-items: center;
}

.person__languages {
  padding-right: v(person-x-space);

  text-align: right;
}

.person__language {
  display: inline;

  text-transform: uppercase;

  @include all-but(1) {
    &::before {
      content: ' - ';
      margin: 0 .1em;
    }
  }
}

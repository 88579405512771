@include --w-to('nav-items-stacked', 24em, '');
@include --w-from('silk-logo-big', 50em);

.header {
  position: relative;
  padding: v(x-space) v(x-space) 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (--nav-items-stacked) {
    align-items: flex-start;
  }

  .filter-visible & {
    @media (--to-filters-large) {
      display: none;
    }
  }

  &::before {
    content: '';
    @include position(absolute, 0 0 null 0);
    z-index: -1;

    height: 80rem;

    background: url('https://source.unsplash.com/lFv0V3_2H6s/1600x900') center top / auto 80rem no-repeat;
    opacity: .3;
  }
}

.header__logo-ctn {
  line-height: 0;
}

.header__logo {
  @media (--silk-logo-big) {
    width: 16.8rem;
    height: 5.3rem;
  }
}

.nav {
  @media (--nav-items-stacked) {
    margin-top: -.4rem;
  }
}

.nav-items {
  display: flex;

  @media (--nav-items-stacked) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.nav-item {
  display: flex;

  @media (--silk-logo-big) {
    font-size: 1.8rem;
  }
}


.nav-item__link {
  display: inline-flex;
  align-items: center;
  padding: .8em;

  @media (--nav-items-stacked) {
    padding: .5em 0;
  }

  &:first-letter {
    text-transform: uppercase;
  }
}

.nav-item__icon {
  order: -1;
  margin-right: .7em;
}

.filters {
  margin: 1.5rem 1rem 0;
  padding: 0 .5rem;
  max-height: 100%;

  -webkit-overflow-scrolling: touch; // iOS free scroll (momentum)
  overflow-y: scroll;

  scrollbar-width: thin;
  scrollbar-color: v(accent);

  &::-webkit-scrollbar {
    width: .4rem;
  }

  &::-webkit-scrollbar-thumb {
    background: v(accent);
  }

  @media (--filters-large) {
    margin-left: 0;
    margin-right: 0;

    height: calc(100% - 9rem);
  }

  .filter:not(.filter--visible) & {
    display: none;
  }

  .filter-visible & {
    position: absolute;
    top: v(filters-h);
    left: 0;

    @media (--to-filters-large) {
      position: fixed;
      top: v(filter-bottom-offset);
      bottom: v(filters-controls-h);
    }

    @media (--filters-large) {
      top: 7rem;
    }
  }
}

$bp-people: (
  'people-small': 57rem,
);

@include --w($bp-people);

.people-grid {
  --people-min: 27rem;

  padding: v(x-space);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(v(people-min), 1fr));
  grid-gap: 2rem;

  align-items: stretch;

  @media (--people-small) {
    --people-min: 33rem;
  }

  .filter-visible & {
    display: none;
  }
}

.fillable {
  --placeholder-color: #{$bombay};
  margin: 1rem 0;
  padding: 0 1rem;
  display: inline-flex;
  flex-direction: column;

  border-bottom: .2rem solid (v(primary));

  font-size: 1.5rem;
}
